/* General Styles */
body {
  background-color: #1c1b1d;
  color: #d0d0d0;
  font-family: 'Courier New', monospace;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* App Header */
.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.App-logo {
  height: 40px;
  filter: grayscale(100%);
  align-items: center;
}

textarea {
  background-color: #2b2b2b;
  color: #d0d0d0;
  border: 1px solid #6b6b6b;
  font-family: 'Courier New', monospace;
  align-items: center;
}

button {
  background-color: #4d4d4d;
  color: #d0d0d0;
  border: 1px solid #6b6b6b;
  font-family: 'Courier New', monospace;
  padding: 5px 10px;
}

/* App Results */
.App-results {
  border: 2px solid #6b6b6b;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-results-no-border {
  border: 2px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column {
  border: 1px solid #6b6b6b;
  padding: 10px;
  margin: 10px;
}

.column-no-border {
  padding: 10px;
  margin: 10px;
}

.App-text {
  color: #d0d0d0;
  font-family: 'Courier New', monospace;
}

.App-text-with-border {
  color: #d0d0d0;
  font-family: 'Courier New', monospace;
  border: 1px solid #6b6b6b;
}

.website-preview {
  border: 1px solid #6b6b6b;
}
